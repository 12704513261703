    import moment from 'moment';
    import React, { useState } from 'react';
    import * as XLSX from 'xlsx-js-style';
    import { ImportNurseSlot } from '../../services/Api/Module/ImportNurseSlot';
    const TestImportNurse = () => {
        const [payload, setPayload] = useState([]);
        const [notFound, setNotFound] = useState([]);

        const createInputxlsx = (e) => {
            const f = e.target.files[0];
            const reader = new FileReader();
            reader.onload = (evt) => {
                const bstr = evt.target.result;
                const wb = XLSX.read(bstr, { type: "binary" });
                const wsname = wb.SheetNames[0];
                const ws = wb.Sheets[wsname];
                let csvData = XLSX.utils.sheet_to_csv(ws, { header: 1 });
                console.log('csvData', csvData);

                const data = csvToData(csvData);
                setPayload(data);
                console.log('Generated Payload:', data);
            };
            reader.readAsBinaryString(f);
        };

        const csvToData = (csv) => {
            const lines = csv.split("\n");
            const result = [];
            const header = lines[1].split(","); // The second row is the header row
            const daysInMonth = header.slice(3).map(day => day.trim());

            const monthYearStr = header[1];
            const parsedDate = moment(monthYearStr, 'MMMM YYYY');
            const yearMonth = parsedDate.format('YYYY-MM');

            for (let i = 3; i < lines.length; i++) { // Start from the 4th line (index 3)
                if (!lines[i].trim()) continue; // Skip empty lines

                const columns = lines[i].split(",");
                const timeStart = columns[1].trim();
                const timeEnd = columns[2].trim();
                const bookings = columns.slice(3); // Booking amounts start from the 4th column

                bookings.forEach((amount, index) => {
                    const bookingAmount = parseInt(amount.trim(), 10);
                    if (bookingAmount > 0) { // Only add entries with bookingAmount > 0
                        const day = daysInMonth[index];
                        const consultDate = `${yearMonth}-${String(day).padStart(2, '0')}`;

                        result.push({
                            uploadSelected: "Nurse",
                            consultDate,
                            consultTimeStart: timeStart,
                            consultTimeEnd: timeEnd,
                            bookingAmount
                        });
                    }
                });
            }

            return result;
        };
        const postImportNurse = async (payload = {}) => {
            try {
                const resApi = await ImportNurseSlot({ data: payload })
                if(resApi.data.resultCode == '40401'){
                    setNotFound(resApi.data.resultData);
                    console.log(`${resApi.data.resultData} not found `)
                }
                if(resApi.data.resultCode == '40301'){
                    setNotFound(resApi.data.resultData);
                    console.log(`${resApi.data.resultData} exist`)
                }
                console.log(resApi)
            } catch (err) {
                console.log('Out error', err)
                alert("มีบางอย่างผิดพลาด")
            }
        };

        return (
            <div>
                <div>
                    <input type="file" accept=".xlsx" onChange={createInputxlsx} />
                </div>
                {/* <div>
                    <pre>{JSON.stringify(payload, null, 2)}</pre>
                </div> */}
                <div>
                    <button onClick={() => postImportNurse(payload)} disabled={payload.length === 0} className='btn import'>Import Nurse Slot</button>
                </div>
                {notFound.length > 0 && (
                <div>
                    <h3>Data Not Found</h3>
                    <ul>
                        {notFound.map((item, index) => (
                            <li key={index}>{item}</li>
                        ))}
                    </ul>
                </div>
            )}
            </div>
        );
    };

    export default TestImportNurse;
