import React, { useEffect, useState } from 'react';
import * as XLSX from 'xlsx-js-style';
import moment from 'moment';
import { SearchAllDoctor } from '../../services/Api/Module/doctor';

const DoctorTemplate = () => {
    const [doctor, setDoctor] = useState([]);

    const createTemplate = (_doctor = []) => {
        const workbook = XLSX.utils.book_new();

        // Get the current month and year
        const currentMonth = moment();
        const currentMonthYear = currentMonth.format('MMMM YYYY');
        const daysInCurrentMonth = currentMonth.daysInMonth();

        const ws_data = [
            ["Doctor Form", "1=เข้างานช่วงเวลานั้น 0=ไม่เข้างานช่วงเวลานั้นของวันนั้นๆ", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", ""],
            ["", currentMonthYear, "", ...Array.from({ length: daysInCurrentMonth }, (_, i) => i + 1)],
        ];

        const merges = [
            { s: { r: 0, c: 1 }, e: { r: 0, c: 2 + daysInCurrentMonth } },
            { s: { r: 1, c: 1 }, e: { r: 1, c: 2 } },
        ];

        // Loop through each doctor and add their data
        _doctor.forEach((doc, index) => {
            const doctorRowIndex = ws_data.length;
            ws_data.push(["Doctor", doc.doctorCode, `${doc.firstName} ${doc.lastName}`, ...Array.from({ length: daysInCurrentMonth }, () => "")]);
            merges.push({ s: { r: doctorRowIndex, c: 2 }, e: { r: doctorRowIndex, c: 2 + daysInCurrentMonth } });

            ws_data.push(["No", "start", "end", ...Array.from({ length: daysInCurrentMonth }, () => "")]);

            let startHour = 0;
            let startMinute = 0;

            for (let i = 0; i < 72; i++) {
                let endHour = startHour;
                let endMinute = startMinute + 10;

                if (endMinute === 60) {
                    endHour += 1;
                    endMinute = 0;
                }

                let start = `${startHour.toString().padStart(2, '0')}:${startMinute.toString().padStart(2, '0')}`;
                let end = `${endHour.toString().padStart(2, '0')}:${endMinute.toString().padStart(2, '0')}`;

                ws_data.push([i + 1, start, end, ...Array.from({ length: daysInCurrentMonth }, () => 0)]);

                // Increment the start time by 20 minutes for the next row
                startMinute += 20;
                if (startMinute === 60) {
                    startHour += 1;
                    startMinute = 0;
                } else if (startMinute === 70) { // For case like 00:50 + 20 = 01:10
                    startHour += 1;
                    startMinute = 10;
                }
            }
        });

        const worksheet = XLSX.utils.aoa_to_sheet(ws_data);

        // Apply style to the first row
        worksheet['A1'].s = { fill: { fgColor: { rgb: "DAE9F8" } } };

        // Apply style to the second row
        for (let col = 0; col < ws_data[1].length; col++) {
            const cellAddress = XLSX.utils.encode_cell({ r: 1, c: col });
            if (!worksheet[cellAddress]) worksheet[cellAddress] = { t: "s" };
            worksheet[cellAddress].s = { fill: { fgColor: { rgb: "B6D7A8" } } };
        }

        // Apply style to the doctor's information and header rows
        ws_data.forEach((row, rowIndex) => {
            if (row[0] === "Doctor") {
                for (let col = 0; col < row.length; col++) {
                    const cellAddress = XLSX.utils.encode_cell({ r: rowIndex, c: col });
                    if (!worksheet[cellAddress]) worksheet[cellAddress] = { t: "s" };
                    worksheet[cellAddress].s = { fill: { fgColor: { rgb: "EA9999" } } };
                }
            } else if (row[0] === "No") {
                const firstCellAddress = XLSX.utils.encode_cell({ r: rowIndex, c: 0 });
                if (!worksheet[firstCellAddress]) worksheet[firstCellAddress] = { t: "s" };
                worksheet[firstCellAddress].s = { fill: { fgColor: { rgb: "D9D2E9" } } };
                for (let col = 1; col < row.length; col++) {
                    const cellAddress = XLSX.utils.encode_cell({ r: rowIndex, c: col });
                    if (!worksheet[cellAddress]) worksheet[cellAddress] = { t: "s" };
                    worksheet[cellAddress].s = { fill: { fgColor: { rgb: "FFF2CC" } } };
                }
            }
        });

        // Merging cells for the headers and doctor rows
        worksheet["!merges"] = merges;

        // Set column widths
        worksheet['!cols'] = [
            { wch: 12 },   // No
            { wch: 10 },  // start
            { wch: 10 },  // end
            ...Array(daysInCurrentMonth).fill({ wch: 5 }), // 1-31
        ];

        XLSX.utils.book_append_sheet(workbook, worksheet, 'Doctor Form');

        const wbout = XLSX.write(workbook, { bookType: 'xlsx', type: 'binary' });

        function s2ab(s) {
            const buf = new ArrayBuffer(s.length);
            const view = new Uint8Array(buf);
            for (let i = 0; i < s.length; i++) view[i] = s.charCodeAt(i) & 0xFF;
            return buf;
        }

        const blob = new Blob([s2ab(wbout)], { type: "application/octet-stream" });

        const link = document.createElement("a");
        link.href = URL.createObjectURL(blob);
        link.download = "Doctor_Form_Template.xlsx";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }

    const fetchAllDoctorList = async () => {
        try {
            const resApi = await SearchAllDoctor();
            console.log('resApi >>', resApi);
            setDoctor(resApi?.data?.resultData ?? []);
        } catch (err) {
            console.log('Out error', err);
            alert("มีบางอย่างผิดพลาด");
        }
    };

    useEffect(() => {
        fetchAllDoctorList();
    }, []);

    return (
        <div>
            <button size="mini" onClick={() => createTemplate(doctor)}>
                Download Doctor Template
            </button>
        </div>
    );
}

export default DoctorTemplate;
