import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import NotFound from './pages/NotFound';
import Meeting from './pages/Meeting/Meeting';
import ImportSlotExcel from './pages/ImportSlotExcel/ImportSlotExcel';
import TemplateSlotExcelBasic from './pages/ImportSlotExcel/TemplateSlotExcelBasic';
import TestNurseTemplate from './pages/ImportSlotExcel/TestNurseTemplate';
import TestImportNurse from './pages/ImportSlotExcel/TestImportNurse';
import DoctorTemplate from './pages/ImportSlotExcel/DoctorTemplate';
import ImportDoctor from './pages/ImportSlotExcel/ImportDoctor';
import ImportNurse from './pages/ImportSlotExcel/ImportNurse';
// import "primereact/resources/themes/lara-light-cyan/theme.css";

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/meeting-calendar" element={<Meeting />} />
        <Route path="/import-slot-excel" element={<ImportSlotExcel/>} />
        <Route path="/basic" element={<TemplateSlotExcelBasic/>} />
        <Route path="/nurse-template" element={<TestNurseTemplate/>} />
        <Route path="/test-import-nurse" element={<TestImportNurse/>} />
        <Route path="/import-doctor" element={<ImportDoctor/>} />
        <Route path="/import-nurse" element={<ImportNurse/>} />
        <Route path="/doctor-template" element={<DoctorTemplate/>} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </Router>
  );
}

export default App;
