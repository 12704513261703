import moment from 'moment';
import React, { useEffect, useState } from 'react';
import * as XLSX from 'xlsx-js-style';
import _ from 'lodash'
// import { SearchBranchByTreatmentNo } from "../../services/ImportSlot";

let defRow = [
  { v: "Courier: 24", t: "s", s: { font: { name: "Courier", sz: 24 } } },
  { v: "bold & color", t: "s", s: { font: { bold: true, color: { rgb: "FF0000" } } } },
  { v: "fill: color", t: "s", s: { fill: { fgColor: { rgb: 'FFFF00' } } } },
  { v: "line\nbreak", t: "s", s: { alignment: { wrapText: true } } },
];

let mockRes = [
  {
    branchId: "8",
    subTreatments: [
      {
        subTreatmentNo: "ST014",
      },
    ],
  },
  {
    branchId: "12",
    subTreatments: [
      {
        subTreatmentNo: "ST014",
      },
      {
        subTreatmentNo: "ST017",
      },
      {
        subTreatmentNo: "ST025",
      },
    ],
  },
];

const TemplateSlotExcelBasic = () => {
  const [templateData, setTemplateData] =  useState(mockRes)

  const exportToExcel = () => {
    const fileName = "template-import-doctor-slot.xlsx"
    let data0 = [
      { v: "Courier: 24", t: "s", s: { font: { name: "Courier", sz: 24 } } },
      { v: "bold & color",t: "s", s: { font: { bold: true, color: { rgb: "FF0000" } } } },
      { v: "fill: color", t: "s", s: { fill: { fgColor: { rgb: 'FFFF00' } } } },
      { v: "line\nbreak", t: "s", s: { alignment: { wrapText: true } } },
    ];

    let data1 = [
      { v: "test1", t: "s", s: {} },
      { v: "test2", t: "s", s: {} },
      { v: "test3", t: "s", s: {} },
    ]
    let data2 = [
      { v: "big1", t: "s", s: {} },
      { v: "big2", t: "s", s: {} },
      { v: "big3", t: "s", s: {} },
    ]
    let data3 = [
      { v: "mock1", t: "s", s: {} },
      { v: "mock2", t: "s", s: {} },
      { v: "mock3", t: "s", s: {} },
    ]



    let rows = [data0, data1, data2, data3]
    let ws = XLSX.utils.aoa_to_sheet(rows)

    const merge = [
      { s: { r: 1, c: 1 }, e: { r: 1, c: 2 } },
    ];

    ws["!merges"] = merge; 

    ws['!cols'] = [ { width: 16 }, { width: 20 }, { width: 5 }, { width: 5 }, { width: 5 }, { width: 5 }, { width: 5 }];

    let wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "BIG1");
    
    XLSX.writeFile(wb, fileName);
  };



  return (
    <button size="mini" onClick={exportToExcel}>
      Template
    </button>
  );
};

export default TemplateSlotExcelBasic;