import { get, post } from "../Config";

//GET
export function SearchMeetingList({ params }) {
  return get({ url: `/api/sb/v1/meetingList`,params});
}
export function SearchMeetingListById({ params }) {
  return get({ url: `/api/sb/v1/meetingListById`,params});
}

export function cancelBooking({ params }) {
  return post({ url: `/api/sb/v1/cancelBooking`,params});
}