import { get, post,put } from "../Config";

// GET
export function SearchDoctorList({ params }) {
  return get({ url: `/api/sb/v1/doctor`,params});
}

export function SearchAllDoctor(){
  return get({ url:`/api/sb/v1/allDoctor`})
}

export function ImportDoctorSLot({ data }) {
  return post({ url: `/api/sb/v1/importDoctorSlot`,data,timeOut: 100000});
}