import moment from 'moment';
import React, { useState, useEffect, useRef } from 'react';
import { Toast } from 'primereact/toast';
import * as XLSX from 'xlsx-js-style';
import { ImportDoctorSLot } from '../../services/Api/Module/doctor';
import { SearchAllDoctor } from '../../services/Api/Module/doctor';

const ImportDoctor = () => {
    const [payload, setPayload] = useState([]);
    const [doctor, setDoctor] = useState([]);
    const [notFound, setNotFound] = useState([]);
    const [exist, setExist] = useState([]);

    // Toast
    const toast = useRef(null);

    const createTemplate = (_doctor = []) => {
        const workbook = XLSX.utils.book_new();

        // Get the current month and year
        const currentMonth = moment();
        const currentMonthYear = currentMonth.format('MMMM YYYY');
        const daysInCurrentMonth = currentMonth.daysInMonth();

        const ws_data = [
            ["Doctor Form", "1=เข้างานช่วงเวลานั้น 0=ไม่เข้างานช่วงเวลานั้นของวันนั้นๆ", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", ""],
            ["", currentMonthYear, "", ...Array.from({ length: daysInCurrentMonth }, (_, i) => i + 1)],
        ];

        const merges = [
            { s: { r: 0, c: 1 }, e: { r: 0, c: 2 + daysInCurrentMonth } },
            { s: { r: 1, c: 1 }, e: { r: 1, c: 2 } },
        ];

        // Loop through each doctor and add their data
        _doctor.forEach((doc, index) => {
            const doctorRowIndex = ws_data.length;
            ws_data.push(["Doctor", doc.doctorCode, `${doc.firstName} ${doc.lastName}`, ...Array.from({ length: daysInCurrentMonth }, () => "")]);
            merges.push({ s: { r: doctorRowIndex, c: 2 }, e: { r: doctorRowIndex, c: 2 + daysInCurrentMonth } });

            ws_data.push(["No", "start", "end", ...Array.from({ length: daysInCurrentMonth }, () => "")]);

            let startHour = 0;
            let startMinute = 0;

            for (let i = 0; i < 72; i++) {
                let endHour = startHour;
                let endMinute = startMinute + 10;

                if (endMinute === 60) {
                    endHour += 1;
                    endMinute = 0;
                }

                let start = `${startHour.toString().padStart(2, '0')}:${startMinute.toString().padStart(2, '0')}`;
                let end = `${endHour.toString().padStart(2, '0')}:${endMinute.toString().padStart(2, '0')}`;

                ws_data.push([i + 1, start, end, ...Array.from({ length: daysInCurrentMonth }, () => 0)]);

                // Increment the start time by 20 minutes for the next row
                startMinute += 20;
                if (startMinute === 60) {
                    startHour += 1;
                    startMinute = 0;
                } else if (startMinute === 70) { // For case like 00:50 + 20 = 01:10
                    startHour += 1;
                    startMinute = 10;
                }
            }
        });

        const worksheet = XLSX.utils.aoa_to_sheet(ws_data);

        // Apply style to the first row
        worksheet['A1'].s = { fill: { fgColor: { rgb: "DAE9F8" } } };

        // Apply style to the second row
        for (let col = 0; col < ws_data[1].length; col++) {
            const cellAddress = XLSX.utils.encode_cell({ r: 1, c: col });
            if (!worksheet[cellAddress]) worksheet[cellAddress] = { t: "s" };
            worksheet[cellAddress].s = { fill: { fgColor: { rgb: "B6D7A8" } } };
        }

        // Apply style to the doctor's information and header rows
        ws_data.forEach((row, rowIndex) => {
            if (row[0] === "Doctor") {
                for (let col = 0; col < row.length; col++) {
                    const cellAddress = XLSX.utils.encode_cell({ r: rowIndex, c: col });
                    if (!worksheet[cellAddress]) worksheet[cellAddress] = { t: "s" };
                    worksheet[cellAddress].s = { fill: { fgColor: { rgb: "EA9999" } } };
                }
            } else if (row[0] === "No") {
                const firstCellAddress = XLSX.utils.encode_cell({ r: rowIndex, c: 0 });
                if (!worksheet[firstCellAddress]) worksheet[firstCellAddress] = { t: "s" };
                worksheet[firstCellAddress].s = { fill: { fgColor: { rgb: "D9D2E9" } } };
                for (let col = 1; col < row.length; col++) {
                    const cellAddress = XLSX.utils.encode_cell({ r: rowIndex, c: col });
                    if (!worksheet[cellAddress]) worksheet[cellAddress] = { t: "s" };
                    worksheet[cellAddress].s = { fill: { fgColor: { rgb: "FFF2CC" } } };
                }
            }
        });

        // Merging cells for the headers and doctor rows
        worksheet["!merges"] = merges;

        // Set column widths
        worksheet['!cols'] = [
            { wch: 12 },   // No
            { wch: 10 },  // start
            { wch: 10 },  // end
            ...Array(daysInCurrentMonth).fill({ wch: 5 }), // 1-31
        ];

        XLSX.utils.book_append_sheet(workbook, worksheet, 'Doctor Form');

        const wbout = XLSX.write(workbook, { bookType: 'xlsx', type: 'binary' });

        function s2ab(s) {
            const buf = new ArrayBuffer(s.length);
            const view = new Uint8Array(buf);
            for (let i = 0; i < s.length; i++) view[i] = s.charCodeAt(i) & 0xFF;
            return buf;
        }

        const blob = new Blob([s2ab(wbout)], { type: "application/octet-stream" });

        const link = document.createElement("a");
        link.href = URL.createObjectURL(blob);
        link.download = "Doctor_Form_Template.xlsx";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }

    const fetchAllDoctorList = async () => {
        try {
            const resApi = await SearchAllDoctor();
            setDoctor(resApi?.data?.resultData ?? []);
        } catch (err) {
            console.log('Out error', err);
            alert("มีบางอย่างผิดพลาด");
        }
    };

    useEffect(() => {
        fetchAllDoctorList();
    }, []);
    const postImportDoctor = async (payload = {}) => {
        try {
            const resApi = await ImportDoctorSLot({ data: payload });
            if  (resApi?.data?.resultCode == '20000'){
                // alert('Import Success');
                toast.current.show({ severity:'success', summary: 'Success', detail:'Import Success', life: 3000 });
            }
            if (resApi?.data?.resultCode == '40401') {
                setNotFound(resApi?.data?.resultData);
                toast.current.show({ severity:'warn', summary: 'Warning', detail:'Data not found', life: 3000 });
                console.log(`${resApi.data.resultData} not found `)
            }
            if (resApi?.data?.resultCode == '40301') {
                setExist(resApi?.data?.resultData);
                toast.current.show({ severity:'warn', summary: 'Warning', detail:'Data exist', life: 3000 });
                console.log(`${resApi?.data?.resultData} data exist`)
            }
            console.log(resApi);
        } catch (err) {
            console.log('Out error', err);
            toast.current.show({ severity:'error', summary: 'Error', detail:'Error something went wrong', life: 3000 });
            // alert("มีบางอย่างผิดพลาด");
        }
    };

    const createInputxlsx = (e) => {
        const f = e.target.files[0];
        const reader = new FileReader();
        reader.onload = (evt) => {
            const bstr = evt.target.result;
            const wb = XLSX.read(bstr, { type: "binary" });
            const wsname = wb.SheetNames[0];
            const ws = wb.Sheets[wsname];
            let csvData = XLSX.utils.sheet_to_csv(ws, { header: 1 });
            console.log('csvData', csvData);

            const data = csvToData(csvData);
            setPayload(data);
            console.log('Generated Payload:', data);
        };
        reader.readAsBinaryString(f);
    };

    const csvToData = (csv) => {
        const lines = csv.split("\n");
        const result = [];
        const header = lines[1].split(","); // The second row is the header row
        const daysInMonth = header.slice(3).map(day => day.trim());

        const monthYearStr = header[1];
        const parsedDate = moment(monthYearStr, 'MMMM YYYY');
        const yearMonth = parsedDate.format('YYYY-MM');

        let currentDoctor = null;

        for (let i = 2; i < lines.length; i++) { // Start from the 3rd line (index 2)
            if (!lines[i].trim()) continue; // Skip empty lines

            const columns = lines[i].split(",");

            if (columns[0] === 'Doctor') {
                currentDoctor = {
                    doctorCode: columns[1].trim(),
                    doctorName: columns[2].trim()
                };
                continue;
            }

            if (columns[0] === 'No') {
                continue;
            }

            const timeStart = columns[1].trim();
            const timeEnd = columns[2].trim();
            const bookings = columns.slice(3); // Booking amounts start from the 4th column

            bookings.forEach((amount, index) => {
                const bookingAmount = parseInt(amount.trim(), 10);
                if (bookingAmount > 0) { // Only add entries with bookingAmount > 0
                    const day = daysInMonth[index];
                    const consultDate = `${yearMonth}-${String(day).padStart(2, '0')}`;

                    result.push({
                        uploadSelected: "doctor",
                        doctorCode: currentDoctor.doctorCode,
                        consultDate,
                        consultTimeStart: timeStart,
                        consultTimeEnd: timeEnd
                    });
                }
            });
        }

        return result;
    };
    return (
        <div>
            <Toast ref={toast} />
            {/* <button onClick={()=> {
                toast.current.show({ severity:'success', summary: 'Success', detail:'Import Success', life: 300000 });
                toast.current.show({ severity:'error', summary: 'Error', detail:'Error something went wrong', life: 300000 });
            }}>toast</button> */}
            <div>
                <h1>Import Doctor Slot File</h1>
                <button
                    style={{
                        backgroundColor: 'black',
                        color: 'white',
                        borderRadius: '12px',
                        padding: '10px 20px',
                        border: 'none',
                        cursor: 'pointer',
                        fontSize: '14px',
                        padding: '10px'
                    }} size="mini" onClick={() => createTemplate(doctor)}>
                    Download Doctor Template
                </button>
            </div>
            <div style={{
                padding: '10px'
            }}>
                <input type="file" accept=".xlsx" onChange={createInputxlsx} />
            </div>
            <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
                <button
                    className='btn import'
                    onClick={() => postImportDoctor(payload)}
                    disabled={payload.length === 0}
                >
                    Import Doctor Slot
                </button>
            </div>
            {notFound?.length > 0 && (
                <div>
                    <h3>Data Not Found</h3>
                    <ul>
                        {notFound?.map((item, index) => (
                            <li key={index}>{item}</li>
                        ))}
                    </ul>
                </div>
            )}
            {exist?.length > 0 && (
                <div>
                    <h3>ข้อมูลซ้ำ</h3>
                    <table style={{ borderCollapse: 'collapse', width: '100%' }}>
                        <thead>
                            <tr>
                                <th style={{ border: '1px solid black', padding: '8px' }}>ID</th>
                                <th style={{ border: '1px solid black', padding: '8px' }}>Doctor</th>
                                <th style={{ border: '1px solid black', padding: '8px' }}>Date</th>
                                <th style={{ border: '1px solid black', padding: '8px' }}>Start</th>
                                <th style={{ border: '1px solid black', padding: '8px' }}>End</th>
                            </tr>
                        </thead>
                        <tbody>
                            {exist.map((item, index) => (
                                <tr key={index}>
                                    <td style={{ border: '1px solid black', padding: '8px' }}>{item.id}</td>
                                    <td style={{ border: '1px solid black', padding: '8px' }}>{item.name}</td>
                                    <td style={{ border: '1px solid black', padding: '8px' }}>{moment(item.consult_date).utcOffset(7).format('YYYY-MM-DD')}</td>
                                    <td style={{ border: '1px solid black', padding: '8px' }}>{item.consult_time_start}</td>
                                    <td style={{ border: '1px solid black', padding: '8px' }}>{item.consult_time_end}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            )}
        </div>
    );
}

export default ImportDoctor;
