import React, { useState, useEffect } from "react";
import { Dialog } from "primereact/dialog";
import moment from 'moment';
import MeetingConfrim from "./MeetingConfrim";

function MeetingDetail({
  open = false,
  vId = 0,
  vType = "DOCTOR",
  onClose = () => console.log("onClose"),
  onRefund = () => console.log("onRefund"),
  onNoRefund = () => console.log("onNoRefund"),
}) {
  const [viewData, setViewData] = useState({});
  const [openConfrim, setOpenConfrim] = useState(false);
  const formattedDate = vId?.start ? moment(vId.start).format('dddd, D MMM') : '';
  const startTime = vId?.start ? moment(vId.start).format('HH:mm') : '';
  const endTime = vId?.end ? moment(vId.end).format('HH:mm') : '';
  const handleOnClose = () => {
    setOpenConfrim(false)
    onClose()
  }
  const handleOnClose1 = () => {
    setOpenConfrim(false)
  }
  useEffect(() => {
    if (open) {
      const payload = {
        id: vId,
        type: vType,
      };
      // setViewData()
    } else {
      setViewData({}); // clear เมื่อปิด
    }
  }, [open]);

  const handleCopyToClipboard = () => {
    if (vId) {
      const textToCopy = `Meeting Link: ${vId.link}
  ชื่อคนไข้: ${vId.firstName} ${vId.lastName}
  รหัสการจอง: ${vId.bookingOrder}
  สถานะ(ขณะจอง): ${vId.motherStage == "Lactating" ? "คุณแม่ให้นมบุตร" : "คุณแม่ตั้งครรภ์"}
  ${vId.motherStage == "Lactating" ? "อายุบุตร" : "อายุครรภ์"}: ${vId.babyAge !== 0 ? vId.babyAge : vId.pregnancyAge} ${vId.motherStage == "Lactating" ? "เดือน" : "สัปดาห์"}
  หัวข้อที่ปรึกษา: ${vId.consultTopic}
  รายละเอียดเพิ่มเติม: ${vId.note}
  หมายเลขโทรศัพท์: ${vId.contactNumber}
  PDPA: ${vId.isConsent ? "ยินยอม" : "ไม่ยินยอม"}`;
      
      const textArea = document.createElement("textarea");
      textArea.value = textToCopy;
      document.body.appendChild(textArea);
      textArea.select();
      try {
        document.execCommand("copy");
        alert("Details copied to clipboard");
      } catch (err) {
        console.error("Failed to copy the details: ", err);
      }
      document.body.removeChild(textArea);
    }
  };
  

  return (
    <Dialog
      visible={open}
      style={{ width: "500px" }}
      onHide={() => onClose()}
      draggable={false}
      resizable={false}
      closable={false}
    >
      <div
        className="bModal bCard"
        id="confirmModal"
        style={{ display: "block" }}
      >
        <div className="closeBtn btnCloseModal" onClick={onClose}></div>
        <div className="bInner-calendarDetail">
          <div className="bTitle">
            <div className="bHead">
              <h2>{vId?.consultSelected == "nurse" ? "คุยกับพยาบาล" : "คุยกับหมอ"}</h2>
              <p>{formattedDate} - {startTime} to {endTime}</p>
              <div className="bLinkOut">
                <div className="bIcon">
                  <div className="iconLine"></div>
                  <a href={vId?.link} target="_blank">เข้าร่วม Line Video Call</a>
                </div>
                <div className="iconCopy" onClick={handleCopyToClipboard}></div>
              </div>
            </div>
            <div className="tUserDetail">
              <div className="icon"></div>
              <div className="tName">
                <p>ชื่อคนไข้</p>
                <p>{vId?.firstName} {vId?.lastName}</p>
              </div>
            </div>
            <div className="tMeetingDetail">
              <p>รหัสการจอง</p>
              <p className="tBold">
                {vId?.bookingOrder}
              </p>
              <p>สถานะ(ขณะจอง)</p>
              <p className="tBold">
                {vId?.motherStage == "Lactating" ? "คุณแม่ให้นมบุตร":"คุณแม่ตั้งครรภ์"}
              </p>
              <p>{vId?.motherStage == "Lactating" ? "อายุบุตร":"อายุครรภ์"}</p>
              <p className="tBold">{vId?.babyAge !== 0 ? vId?.babyAge : vId?.pregnancyAge} {vId?.motherStage == "Lactating" ? "เดือน":"สัปดาห์"}</p>
              <p>หัวข้อที่ปรึกษา</p>
              <p className="tBold">{vId?.consultTopic}</p>
              <p>รายละเอียดเพิ่มเติม</p>
              <p className="tBold">{vId?.note}</p>
              <p>หมายเลขโทรศัพท์</p>
              <p className="tBold">{vId?.contactNumber}</p>
              <p>PDPA</p>
              <p className="tBold">{vId?.isConsent ? "ยินยอม" : "ไม่ยินยอม"}</p>

            </div>
          </div>
          <div className="bBtn btnCloseModal">
            <button
              onClick={() => setOpenConfrim(true)}
            >ยกเลิกนัด</button>
          </div>
        </div>
      </div>
      <MeetingConfrim
        open={openConfrim}
        onRefund={onRefund}
        onNoRefund={onNoRefund}
        onClose={handleOnClose}
        onClose1={handleOnClose1}
      />
    </Dialog>
  );
}

export default MeetingDetail;
