import React from "react";
import { Dialog } from 'primereact/dialog';
function MeetingConfrim({ 
  open = false,
  onRefund = () => console.log('onRefund'),
  onNoRefund = () => console.log('onNoRefund'),
  onClose = () => console.log('onClose'),
  onClose1 = () => console.log('onClose1')
}) {
  const handleRefund = () => {
    onRefund();
    onClose();
  };

  const handleNoRefund = () => {
    onNoRefund();
    onClose();
  };

  return (
    <Dialog visible={open} style={{ width: '500px' }} onHide={() => onClose1()} draggable={false} resizable={false} closable={false}>
      <div className="bModal bCard" id="confirmModal-cancel" style={{ display: 'block' }}>
        <div className="closeBtn" onClick={onClose1}></div>
        <div className="bInner">
          <div className="bTitle">
            <span>
            ยืนยันที่จะลบนัดหมายนี้
            </span>
          
          </div>
          <div className="bBtn">
            <button
              className="btn btnCloseModal"
              onClick={() => handleRefund()}
            >
              ยืนยัน และ Refund Point
            </button>
            <button
              className="btn outline btnCloseModal"
              onClick={() => handleNoRefund()}
            >
              ยืนยัน แต่ไม่ Refund Point
            </button>
          </div>
        </div>
      </div>
    </Dialog>
  );
}

export default MeetingConfrim;
