import React, { useState, useEffect } from "react";
import { Dialog } from "primereact/dialog";

function MeetingConfrimCancel({
  open = false,
  onYes = () => console.log("onYes"),
  onNo = () => console.log("onNo"),
}) {
  return (
    <Dialog
      visible={open}
      style={{ width: "500px" }}
      onHide={() => onNo()}
      draggable={false}
      resizable={false}
      closable={false}
    >
      <div
        className="bModal bCard"
        id="confirmModal-cancel"
        style={{ display: "block" }}
      >
        <div className="bInner">
          <div className="bTitle">
            <span>การลบนัดหมายสำเร็จ</span>
          </div>
          <div className="bBtn">
            <button className="btn btnCloseModal" onClick={() => onYes()}>
             ตกลง
            </button>
          </div>
        </div>
      </div>
    </Dialog>
  );
}

export default MeetingConfrimCancel;
