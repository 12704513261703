import React, { useState, useEffect } from "react";
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import MeetingDetail from "./MeetingDetail";
import HeaderAction from "./Header";
import { SearchAllDoctor } from "../../services/Api/Module/doctor";
import { SearchMeetingList, cancelBooking } from "../../services/Api/Module/CalendarMeeting";
import "moment/locale/en-gb";
import ModalNetwork from "./ModalNetwork";
import MeetingConfrimCancel from "./MeetingConfrimCancel";
moment.updateLocale("en-gb", {
  week: {
    dow: 0, // Sunday is the first day of the week
  },
});

const localizer = momentLocalizer(moment);

const Meeting = () => {
  const [openPopup, setOpenPopup] = useState(false);
  const [type, setType] = useState("doctor");
  const [viewId, setViewId] = useState(null);
  const [allDoctor, setAllDoctor] = useState([]);
  const [meetingList, setMeetingList] = useState([]);
  const [doctorId, setDoctorId] = useState("");
  const [events, setEvents] = useState([]);
  const [currentDate, setCurrentDate] = useState(new Date());
  const [startDate, setStartDate] = useState(moment().startOf('month').toDate());
  const [endDate, setEndDate] = useState(moment().endOf('month').toDate());
  const [refresh, setRefresh] = useState(false);
  const [failCancel, setFailCancel] = useState(false);
  const [success,setSuccess] = useState(false);

  const handleEventClick = (event) => {
    const updatedEvent = {
      ...event,
      consultSelected: doctorId === "nurse" ? "nurse" : "doctor"
    };
    setViewId(updatedEvent);
    setOpenPopup(true);
  };

  const handleSelectDoctor = (event) => {
    setDoctorId(event);
  };
  const handleRefresh = () =>{
    setFailCancel(false)
    setSuccess(false)
    setRefresh(!refresh);
  }
  const handleRefund = () => {
    const payload = {
      id: viewId.id,
      consultSelected: viewId.consultSelected,
      refund: 1
    };
    fetchCancelBooking(payload);
  };

  const handleNoRefund = () => {
    const payload = {
      id: viewId.id,
      consultSelected: viewId.consultSelected,
      refund: 0
    };
    fetchCancelBooking(payload);
  };

  const handleNavigate = (date, view) => {
    const mDate = moment(date);
    setCurrentDate(mDate.toDate());

    let start, end;
    switch (view) {
      case 'month':
        start = mDate.startOf('month').toDate();
        end = mDate.endOf('month').toDate();
        break;
      case 'week':
        start = mDate.startOf('week').toDate();
        end = mDate.endOf('week').toDate();
        break;
      case 'day':
        start = mDate.startOf('day').toDate();
        end = mDate.endOf('day').toDate();
        break;
      default:
        start = mDate.startOf('month').toDate();
        end = mDate.endOf('month').toDate();
        break;
    }
    setStartDate(start);
    setEndDate(end);
  };

  const fetchAllDoctorList = async () => {
    try {
      const resApi = await SearchAllDoctor();
      const doctors = resApi?.data?.resultData ?? [];
      setAllDoctor(doctors);
      if (doctors.length > 0 && doctorId === "") {
        setDoctorId('nurse');
        // setDoctorId(doctors[0].id); // Set the first doctor's ID only if doctorId is not already set
      }
    } catch (err) {
      console.log('Out error', err);
      alert("มีบางอย่างผิดพลาด");
    }
  };

  const fetchCancelBooking = async (payload) => {
    try {
      const resApi = await cancelBooking({ params: { ...payload } });
      if(resApi?.data?.resultCode == "20000"){
        setSuccess(true)
      }
      if(resApi?.data?.resultCode == "40402"){
        setFailCancel(true)
      }
    } catch (err) {
      console.log('Out error', err);
      alert("มีบางอย่างผิดพลาด");
    }
  };

  const fetchMeetingList = async (doctorId) => {
    const newPayload = doctorId === "nurse" ? {
      consultSelected: "nurse",
      startDate: moment(startDate).format('YYYY-MM-DD'),
      endDate: moment(endDate).format('YYYY-MM-DD')
    } : {
      sm_doctor: doctorId,
      consultSelected: "doctor",
      startDate: moment(startDate).format('YYYY-MM-DD'),
      endDate: moment(endDate).format('YYYY-MM-DD')
    };
    try {
      const resApi = await SearchMeetingList({ params: { ...newPayload } });
      setMeetingList(resApi?.data?.resultData ?? []);
    } catch (err) {
      console.log('Out error', err);
      alert("มีบางอย่างผิดพลาด");
    }
  };

  useEffect(() => {
    fetchAllDoctorList();
  }, []);

  useEffect(() => {
    if (doctorId) {
      fetchMeetingList(doctorId);
    }
  }, [doctorId, currentDate, refresh, startDate, endDate]);

  useEffect(() => {
    const events = meetingList.map(meeting => ({
      id: meeting.id,
      title: `จอง ${moment(meeting.consultTimeStart, 'HH:mm:ss').format('HH:mm')} - ${moment(meeting.consultTimeEnd, 'HH:mm:ss').format('HH:mm')}`,
      start: moment(meeting.consultDate).set({
        hour: moment(meeting.consultTimeStart, 'HH:mm:ss').hours(),
        minute: moment(meeting.consultTimeStart, 'HH:mm:ss').minutes()
      }).toDate(),
      end: moment(meeting.consultDate).set({
        hour: moment(meeting.consultTimeEnd, 'HH:mm:ss').hours(),
        minute: moment(meeting.consultTimeEnd, 'HH:mm:ss').minutes()
      }).toDate(),
      link: meeting.meetingLink,
      firstName: meeting.firstName,
      lastName: meeting.lastName,
      contactNumber: meeting.contactNumber,
      motherStage: meeting.motherStage,
      babyAge: meeting.babyAge,
      pregnancyAge: meeting.pregnancyAge,
      isConsent: meeting.isConsent,
      note: meeting.note,
      consultTopic: meeting.consultTopic,
      bookingOrder: meeting.bookingOrder
    }));
    setEvents(events);
  }, [meetingList]);

  return (
    <div>
      <HeaderAction doctor={allDoctor} handleSelectDoctor={handleSelectDoctor} value={doctorId} />

      <Calendar
        localizer={localizer}
        events={events}
        startAccessor="start"
        endAccessor="end"
        style={{ height: 800 }}
        className="custom-calendar"
        onSelectEvent={handleEventClick}
        onNavigate={(date, view) => {
          handleNavigate(date, view);
        }}
        views={['month', 'week', 'day']}
        onView={(view) => {
          handleNavigate(currentDate, view); // Call handleNavigate with current date and view
        }}
        firstDayOfWeek={0} // Explicitly set the first day of the week to Sunday
      />

      <MeetingDetail
        open={openPopup}
        vId={viewId}
        vType={type}
        onClose={() => setOpenPopup(false)}
        onRefund={handleRefund}
        onNoRefund={handleNoRefund}
      />
      <ModalNetwork open={failCancel} onClose={handleRefresh}/>
      <MeetingConfrimCancel open={success} onYes={handleRefresh}/>
    </div>
  );
};

export default Meeting;
