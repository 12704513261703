import React from 'react';

function HeaderAction({
  doctor = false,
  handleSelectDoctor = () => console.log('onClose'),
  value = "",
}) {
  const handleChange = (event) => {
    handleSelectDoctor(event.target.value);
  };
  return (
    <div>
      <div className="bHead">
        <div className="iconBurger"></div>
        <h1>Calendar Meeting</h1>
      </div>
      <div className="bHeaderActiont">
        <div className="bDoctorSearch">
          <div className="control-group validate error">
            <div className="select">
              <select onChange={handleChange} value={value}>
                <option value={'nurse'}>พยาบาล</option>
                {doctor.map((doc, index) => (
                  <option key={index} value={doc.id}>{doc.firstName} {doc.lastName}</option>
                ))}
              </select>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default HeaderAction;
